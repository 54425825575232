



















import Vue from 'vue'
import lottie from 'lottie-web'
import type { AnimationItem } from 'lottie-web'
import { withNonReactive } from '@simpl/core/utils/with-non-reactive'

export default Vue.extend({
  name: 'LottiePlayer',

  props: {
    url: String,
    speed: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: -1
    },
    height: {
      type: Number,
      default: -1
    },
    loop: {
      type: Boolean,
      default: true
    },
    autoPlay: Boolean,
    playOnHover: Boolean
  },
  data () {
    return withNonReactive({
      name: 'lottie-animation',
      loaded: false,
      rendererSettings: {
        scaleMode: 'centerCrop',
        clearCanvas: true,
        progressiveLoad: false,
        hideOnTransparent: true
      },
      style: null! as Record<string, any>
    })<{ anim: AnimationItem }>()
  },

  computed: {
    isAssetDragActive () {
      return !!this.$store.state.cms?._dragDropAssetData
    }
  },

  watch: {
    url () {
      this.init()
    },
    isAssetDragActive (v) {
      if (!this.anim) return

      if (v) { this.anim.stop() } else if (this.autoPlay) { this.anim.play() }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    async loadJsonData (url: string) {
      return (await this.$http.get(url)).data
    },
    async init () {
      if (!this.url) return

      this.loaded = false

      this.style = {
        width: (this.width !== -1) ? `${this.width}px` : '100%',
        height: (this.height !== -1) ? `${this.height}px` : '100%',
        overflow: 'hidden',
        margin: '0 auto'
      }
      const jsonData = await this.loadJsonData(this.url)
      if (this.anim) {
        this.anim.destroy() // Releases resources. The DOM element will be emptied.
      }
      this.anim = lottie.loadAnimation({
        container: this.$refs.lavContainer as Element,
        renderer: 'svg',
        loop: this.loop,
        autoplay: this.autoPlay && !this.isAssetDragActive,
        animationData: jsonData,
        rendererSettings: this.rendererSettings
      })

      this.loaded = true

      if (!this.autoPlay || this.isAssetDragActive) {
        this.anim.goToAndStop(10, true)
      }

      this.anim.setSpeed(this.speed)
    },

    onMouseEnter () {
      if (!this.playOnHover || !this.anim) return

      this.anim.play()
    },
    onMouseLeave () {
      if (!this.playOnHover || !this.anim) return

      this.anim.stop()
    }
  }
}
)
